import type { AppProps } from 'next/app'
import GlobalStyle from 'styles/GlobalStyle'
import { ThemeProvider } from 'styled-components'
import theme from 'styles/theme'
import GlobalContextsProvider from 'layout/GlobalContextsProvider'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <GlobalContextsProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Component {...pageProps} />
      </ThemeProvider>
    </GlobalContextsProvider>
  )
}

export default MyApp

import { css } from 'styled-components'

export const colors = {
  /** Brand colors */
  blue: '#005EEB',
  blueHover: '#004AD7',
  paleBlue: '#EFF6FE',
  paleBlueHover: '#DFEDFD',
  navy: '#00234F',
  /** Secondary colors */
  orange: '#FF925B',
  green: '#00C087',
  red: '#FF5A5E',
  aquamarine: '#23D8AC',
  /** Text & Background */
  charcoal: 'rgba(55,52,47,1)',
  charcoal60: 'rgba(55,52,47,0.6)',
  charcoal40: 'rgba(55,52,47,0.4)',
  charcoal20: 'rgba(55,52,47,0.2)',
  charcoal10: 'rgba(55,52,47,0.1)',
  charcoal5: 'rgba(55,52,47,0.05)',
  white: 'rgba(255,255,255,1)',
  white90: 'rgba(255,255,255,0.9)',
  white60: 'rgba(255,255,255,0.6)',
  white30: 'rgba(255,255,255,0.3)',
  white20: 'rgba(255,255,255,0.2)',
  white10: 'rgba(255,255,255,0.1)',
  white5: 'rgba(255,255,255,0.05)',
  chocolate: 'rgba(205, 96, 41, 1)',
  chocolate20: 'rgba(255, 146, 91, 0.2)',
  navy90: 'rgba(0, 35, 79, 0.9)',
  /** Error */
  errorLight: '#E60004',
  errorDark: '#FF474A',
}

export const typography = {
  h1: {
    mobile: css`
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      letter-spacing: -0.03em;
    `,
    desktop: css`
      font-weight: 700;
      font-size: 60px;
      line-height: 64px;
      letter-spacing: -0.04em;
    `,
  },
  h2: {
    mobile: css`
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      letter-spacing: -0.02em;
    `,
    desktop: css`
      font-weight: 700;
      font-size: 40px;
      line-height: 46px;
      letter-spacing: -0.03em;
    `,
  },
  h3: {
    mobile: css`
      font-weight: 600;
      font-size: 19px;
      line-height: 27px;
      letter-spacing: -0.03em;
    `,
    desktop: css`
      font-weight: 600;
      font-size: 26px;
      line-height: 34px;
      letter-spacing: -0.03em;
    `,
  },
  h4: {
    mobile: css`
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.02em;
    `,
    desktop: css`
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -0.01em;
    `,
  },
  h5: {
    mobile: css`
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
    `,
    desktop: css`
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: -0.01em;
    `,
  },
  h6: {
    mobile: css`
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    `,
    desktop: css`
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    `,
  },
  mega: {
    mobile: css`
      font-weight: 700;
      font-size: 56px;
      line-height: 62px;
      letter-spacing: -0.04em;
    `,
    desktop: css`
      font-weight: 700;
      font-size: 88px;
      line-height: 88px;
      letter-spacing: -0.04em;
    `,
  },
  'body-large': {
    mobile: css`
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
    `,
    desktop: css`
      font-weight: 400;
      font-size: 17px;
      line-height: 27px;
    `,
  },
  'body-large-pullout': {
    mobile: css`
      font-weight: 600;
      font-size: 15px;
      line-height: 25px;
    `,
    desktop: css`
      font-weight: 600;
      font-size: 17px;
      line-height: 27px;
    `,
  },
  'body-small': {
    mobile: css`
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
    `,
    desktop: css`
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
    `,
  },
  'body-small-pullout': {
    mobile: css`
      font-weight: 600;
      font-size: 13px;
      line-height: 21px;
    `,
    desktop: css`
      font-weight: 600;
      font-size: 15px;
      line-height: 23px;
    `,
  },
}

export const surfaces = {
  background: {
    white: colors.white,
    light: colors.paleBlue,
    dark: colors.navy,
  },
  tabButton: {
    variant: {
      blue: {
        background: colors.blue,
        backgroundHover: colors.blueHover,
        color: colors.white,
      },
      pale: {
        background: colors.paleBlue,
        backgroundHover: colors.paleBlueHover,
        color: colors.charcoal,
      },
    },
    size: {
      height: {
        mobile: '32px',
        desktop: '40px',
      },
      padding: {
        mobile: '6px 12px',
        desktop: '10px 12px',
      },
      borderRadius: {
        mobile: '8px',
        desktop: '10px',
      },
      fontSize: {
        mobile: '14px',
        desktop: '16px',
      },
      lineHeight: {
        mobile: '20px',
        desktop: '20px',
      },
      fontWeight: {
        mobile: 500,
        desktop: 500,
      },
    },
  },
}

const breakpointNumbers = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
}

const breakpointAtleastQueries = {
  xs: `(min-width: 1px)`,
  sm: `(min-width: ${breakpointNumbers.sm}px)`,
  md: `(min-width: ${breakpointNumbers.md}px)`,
  lg: `(min-width: ${breakpointNumbers.lg}px)`,
  xl: `(min-width: ${breakpointNumbers.xl}px)`,
  xxl: `(min-width: ${breakpointNumbers.xxl}px)`,
}

const breakpointUptoQueries = {
  sm: `(max-width: ${breakpointNumbers.sm}px)`,
  md: `(max-width: ${breakpointNumbers.md}px)`,
  lg: `(max-width: ${breakpointNumbers.lg}px)`,
  xl: `(max-width: ${breakpointNumbers.xl}px)`,
  xxl: `(max-width: ${breakpointNumbers.xxl}px)`,
}

const breakpointOnlyQueries = {
  xs: `(min-width: 1px, max-width: ${breakpointNumbers.sm}px)`,
  sm: `(min-width: ${breakpointNumbers.sm}px, max-width: ${breakpointNumbers.md - 1}px)`,
  md: `(min-width: ${breakpointNumbers.md}px, max-width: ${breakpointNumbers.lg - 1}px)`,
  lg: `(min-width: ${breakpointNumbers.lg}px, max-width: ${breakpointNumbers.xl - 1}px)`,
  xl: `(min-width: ${breakpointNumbers.xl}px, max-width: ${breakpointNumbers.xxl - 1}px)`,
  xxl: `(min-width: ${breakpointNumbers.xxl}px`,
}

const shadows = {
  dropdown: {
    light: `0px 25px 45px rgba(0, 94, 235, 0.15)`,
    dark: `0px 25px 45px rgba(255, 255, 255, 0.2)`,
  },
}

export const theme = {
  colors,
  typography,
  surfaces,
  shadows,
  breakpoints: {
    atleast: breakpointAtleastQueries,
    upto: breakpointUptoQueries,
    only: breakpointOnlyQueries,
  },
  breakpointNumbers: breakpointNumbers,
}

export default theme

import { createGlobalStyle, css } from 'styled-components'

import type { TextColor } from './types'
import { kebabCase } from 'lodash'
import theme from './theme'

const GlobalStyle = createGlobalStyle(
  () => css`
    /** Inter Font */

    :root {
      font-family: 'Inter', sans-serif;
    }
    @supports (font-variation-settings: normal) {
      :root {
        font-family: 'Inter var', sans-serif;
      }
    }

    /** General defaults */

    * {
      margin: 0;
      padding: 0;
      scrollbar-width: 1px;
      -webkit-font-smoothing: antialiased;
    }

    body * {
      box-sizing: border-box;
    }

    body {
      font-style: normal !important;
      color: ${theme.colors.charcoal};
      background-color: ${theme.colors.white};
    }

    /** Scrolls */

    .no-scroll {
      overflow: hidden;
    }

    .hide-scroll {
      &::-webkit-scrollbar {
        width: 1px;
      }
    }

    /** Typography */

    body {
      ${theme.typography['body-small'].mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography['body-small'].desktop}
      }
    }

    h1 {
      ${theme.typography.h1.mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography.h1.desktop}
      }
    }

    h2 {
      ${theme.typography.h2.mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography.h2.desktop}
      }
    }

    h3 {
      ${theme.typography.h3.mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography.h3.desktop}
      }
    }

    h4 {
      ${theme.typography.h4.mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography.h4.desktop}
      }
    }

    h5 {
      ${theme.typography.h5.mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography.h5.desktop}
      }
    }

    h6 {
      ${theme.typography.h6.mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography.h6.desktop}
      }
    }

    .mega {
      ${theme.typography.mega.mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography.mega.desktop}
      }
    }

    .body-large {
      ${theme.typography['body-large'].mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography['body-large'].desktop}
      }
    }

    .body-large-pullout {
      ${theme.typography['body-large-pullout'].mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography['body-large-pullout'].desktop}
      }
    }

    .body-small {
      ${theme.typography['body-small'].mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography['body-small'].desktop}
      }
    }

    .body-small-pullout {
      ${theme.typography['body-small-pullout'].mobile}
      @media ${theme.breakpoints.atleast.md} {
        ${theme.typography['body-small-pullout'].desktop}
      }
    }

    .heading strong {
      font-style: normal !important;
      font-weight: inherit;
    }

    a {
      text-decoration: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    /** Links */

    /* a {
    color: ${theme.colors.blue} !important;
    text-decoration: none;
    &:hover {
      color: ${theme.colors.blueHover} !important;
    }
  } */

    /** Blocks */

    .block-scheme-white {
      background-color: ${theme.surfaces.background.white};
      * {
        color: ${theme.colors.charcoal};
        .heading strong {
          color: ${theme.colors.blue};
          font-style: normal;
        }
      }
      a {
        &:hover {
          color: ${theme.colors.blueHover};
        }
      }
    }

    .block-scheme-light {
      background-color: ${theme.surfaces.background.light};
      * {
        color: ${theme.colors.charcoal};
        .heading strong {
          color: ${theme.colors.blue};
          font-style: normal;
        }
      }
      a {
        &:hover {
          color: ${theme.colors.blueHover};
        }
      }
    }

    .block-scheme-dark-blue,
    .block-scheme-dark-neon {
      background-color: ${theme.surfaces.background.dark};
      * {
        color: ${theme.colors.white};
        .heading strong {
          color: ${theme.colors.aquamarine};
          font-style: normal;
        }
      }
      a {
        &:hover {
          color: ${theme.colors.aquamarine};
        }
      }
    }

    /** Text Colors */

    ${Object.keys(theme.colors).map(
      (key) => `.color-${kebabCase(key)} { color: ${theme.colors[key as TextColor]} !important; }`,
    )}
  `,
)

export default GlobalStyle

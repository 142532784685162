import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react'

import { FooterMenu } from '../content/getFooterMenu'
import { HeaderMenu } from '../content/getHeaderMenu'
import { SolutionPreviewElementType } from 'types/strapi/components'
import { USP } from 'types/strapi/objects'

type GlobalContexts = {
  setGlobalData: (state: Partial<GlobalData>) => void

  headerMenu: HeaderMenu
  setHeaderMenu: Dispatch<SetStateAction<HeaderMenu>>

  footerMenu: FooterMenu
  setFooterMenu: Dispatch<SetStateAction<FooterMenu>>

  solutionPreviews: SolutionPreviewElementType[]
  setSolutionPreviews: Dispatch<SetStateAction<SolutionPreviewElementType[]>>

  usps: USP[]
  setUsps: Dispatch<SetStateAction<USP[]>>

  showLeadCaptureModal: boolean
  setShowLeadCaptureModal: Dispatch<SetStateAction<boolean>>

  showThanksModal: boolean
  setShowThanksModal: Dispatch<SetStateAction<boolean>>

  showManageCookiesModal: boolean
  setShowManageCookiesModal: Dispatch<SetStateAction<boolean>>

  showCookieNotice: boolean
  setShowCookieNotice: Dispatch<SetStateAction<boolean>>
}

type GlobalData = {
  headerMenu: HeaderMenu
  footerMenu: FooterMenu
  solutionPreviews: SolutionPreviewElementType[]
  usps: USP[]
  showLeadCaptureModal: boolean
  showThanksModal: boolean
  showManageCookiesModal: boolean
  showCookieNotice: boolean
}

const GlobalContexts = createContext<GlobalContexts>({
  setGlobalData: () => null,

  headerMenu: [],
  setHeaderMenu: () => null,

  footerMenu: [],
  setFooterMenu: () => null,

  solutionPreviews: [],
  setSolutionPreviews: () => null,

  usps: [],
  setUsps: () => null,

  showLeadCaptureModal: false,
  setShowLeadCaptureModal: () => null,

  showThanksModal: false,
  setShowThanksModal: () => null,

  showManageCookiesModal: false,
  setShowManageCookiesModal: () => null,

  showCookieNotice: false,
  setShowCookieNotice: () => null,
})

export const GlobalContextsProvider = ({ children }: { children: React.ReactNode }) => {
  const [headerMenu, setHeaderMenu] = useState<HeaderMenu>([])
  const [footerMenu, setFooterMenu] = useState<FooterMenu>([])
  const [solutionPreviews, setSolutionPreviews] = useState<SolutionPreviewElementType[]>([])
  const [usps, setUsps] = useState<USP[]>([])
  const [showLeadCaptureModal, setShowLeadCaptureModal] = useState(false)
  const [showThanksModal, setShowThanksModal] = useState(false)
  const [showManageCookiesModal, setShowManageCookiesModal] = useState(false)
  const [showCookieNotice, setShowCookieNotice] = useState(false)

  const setGlobalData = ({
    headerMenu,
    footerMenu,
    solutionPreviews,
    usps,
    showLeadCaptureModal,
    showManageCookiesModal,
    showCookieNotice,
  }: Partial<GlobalData> = {}) => {
    if (headerMenu) setHeaderMenu(headerMenu)
    if (footerMenu) setFooterMenu(footerMenu)
    if (solutionPreviews) setSolutionPreviews(solutionPreviews)
    if (usps) setUsps(usps)
    if (showLeadCaptureModal !== undefined) setShowLeadCaptureModal(showLeadCaptureModal)
    if (showThanksModal !== undefined) setShowThanksModal(showThanksModal)
    if (showManageCookiesModal !== undefined) setShowManageCookiesModal(showManageCookiesModal)
    if (showCookieNotice !== undefined) setShowCookieNotice(showCookieNotice)
  }

  return (
    <GlobalContexts.Provider
      value={{
        setGlobalData,
        headerMenu,
        setHeaderMenu,
        footerMenu,
        setFooterMenu,
        solutionPreviews,
        setSolutionPreviews,
        usps,
        setUsps,
        showLeadCaptureModal,
        setShowLeadCaptureModal,
        showThanksModal,
        setShowThanksModal,
        showManageCookiesModal,
        setShowManageCookiesModal,
        showCookieNotice,
        setShowCookieNotice,
      }}
    >
      {children}
    </GlobalContexts.Provider>
  )
}

export const useGlobalContexts = () => useContext(GlobalContexts)

export default GlobalContextsProvider
